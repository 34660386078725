export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/rachelisitalian-logo.jpg',
    banner_img_root : '/img/rachelisitalian-banner.jpg',
    resid : '',
    key_value : 'rachelisitalian',
    secret_value : 'rachelisitalian',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.7535498868137!2d-105.10437648461158!3d40.17004057939458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bf90caa303519%3A0xa43d8704845c310a!2s624%20Main%20St%2C%20Longmont%2C%20CO%2080501%2C%20USA!5e0!3m2!1sen!2sin!4v1614933320562!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Racheli's Italian"
};
